const fontSize = {
  1: '12px',
  2: '14px',
  3: '16px',
  4: '18px',
  5: '20px',
  6: '24px',
  7: '30px',
  8: '36px',
  9: '48px',
  10: '60px',
  11: '72px',
};

export default fontSize;
