import styled from 'styled-components';

import colors from '../../colors';
import device from '../../device';
import fontSize from '../../fontSize';
import layoutSize from '../../layoutSize';

export const Button = styled.a`
  padding: ${layoutSize[2]} ${layoutSize[4]};
  border: none;
  z-index: 1;
  display: block;
  position: relative;
  background-image: ${colors.gradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${fontSize[3]};
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  :hover {
    transform: translateX(2px);
  }
`;

export const CTAButton = styled.a`
  padding: ${layoutSize[2]} ${layoutSize[4]};
  z-index: 1;
  display: block;
  position: relative;
  background-image: ${colors.gradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-image: ${colors.gradient};
  border-image-slice: 1;
  font-size: ${fontSize[3]};
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  &::after {
    background-image: ${colors.gradient};
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }

  :hover {
    -webkit-text-fill-color: #fff;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
`;

export const HeroCTAButton = styled(CTAButton)`
  font-size: ${fontSize[5]};
  @media ${device.tablet} {
    font-size: ${fontSize[6]};
  }
`;

export const ContactCTAButton = styled.a`
  padding: ${layoutSize[2]} ${layoutSize[4]};
  display: block;
  position: relative;
  border-width: 2px;
  font-size: ${fontSize[5]};
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  color: #fff !important;
  border: 2px solid #fff;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  @media ${device.tablet} {
    font-size: ${fontSize[6]};
  }

  &::after {
    background: #fff;
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    background-image: ${colors.gradient};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &::after {
      width: 100%;
    }
  }
`;
