import styled from 'styled-components';

import device from '../../device';
import fontSize from '../../fontSize';
import layoutSize from '../../layoutSize';

const SectionTitle = styled.h2`
  margin-bottom: ${layoutSize[6]};
  font-size: ${fontSize[7]};
  text-align: center;
  @media ${device.tablet} {
    font-size: ${fontSize[8]};
  }
`;

export default SectionTitle;
